<template>
  <component
    :is="to ? NuxtLink : 'button'"
    :to="to ? to : undefined"
    class="transition-all duration-300"
    :class="variant !== 'unstyled' && classList"
    @click="onClick"
  >
    {{ label }}
    <slot v-if="!label" />
    <Icon
      v-if="icon"
      :name="typeof icon === 'string' ? icon : icon.name"
      :size="typeof icon === 'string' ? 'sm' : icon.size"
      :color="typeof icon === 'string' ? 'white group-hover:black ' : icon.color"
    />
  </component>
</template>

<script setup lang="ts">
import type { Icon, Props as IconProps } from './Icon.vue'

const NuxtLink = resolveComponent('NuxtLink')

type ButtonVariant = 'primary' | 'secondary' | 'filter' | 'simple' | 'unstyled'

interface Props {
  disabled?: boolean
  icon?: Icon | IconProps
  variant?: ButtonVariant
  label?: string
  loading?: boolean
  to?: string | object | undefined | null
  prevent?: boolean
  size?: 'default' | 'md' | 'sm'
  active?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  variant: 'primary',
  loading: false,
  prevent: false,
  size: 'default',
  active: false
})

// eslint-disable-next-line
const emit = defineEmits<{
  (event: 'click', e: MouseEvent): void
}>()

const $style = useCssModule()

const classList = computed(() => {
  return [
    // Element
    $style.button,
    {
      // Style
      [$style.primary]: props.variant === 'primary',
      [$style.secondary]: props.variant === 'secondary',
      [$style.filter]: props.variant === 'filter',
      [$style.simple]: props.variant === 'simple',

      // State
      [$style.disabled]: props.disabled || props.loading,
      [$style.active]: props.active
    }
  ]
})

function onClick(event: MouseEvent) {
  if (props.disabled || props.loading || props.prevent) {
    event.preventDefault()
    event.stopPropagation()
  }
  emit('click', event)
}
</script>

<style lang="postcss" module>
.button {
  --size: 56px;
  --webkit-tap-highlight-color: transparent;
  --gap: 14px;

  @apply relative inline-flex h-[var(--size)] cursor-pointer items-center justify-center gap-[var(--gap)] whitespace-nowrap px-3 text-sm uppercase leading-none tracking-widest no-underline;
}

/* Primary */
.primary {
  @apply bg-red text-white;

  &:hover {
    @apply bg-red-70 bg-opacity-70;
  }
}

/* Secondary */

.secondary {
  @apply border border-neutral-200 bg-white text-neutral-900;

  &:hover {
    @apply opacity-80;
  }
}

/* Filter */

.filter {
  --size: 32px;

  @apply border border-neutral-200 bg-white px-5 tracking-normal text-black;

  &:hover {
    @apply bg-green-light text-white;
  }
}

/* Simple */
.simple {
  @apply bg-transparent px-0 text-neutral-900;
}

/* State */
.disabled {
  @apply pointer-events-none cursor-default;

  &.primary {
    @apply bg-neutral-400;
  }

  &.secondary {
    @apply bg-neutral-300;
  }
}

.active {
  &.primary {
    @apply bg-red-70 bg-opacity-70;
  }

  &.secondary {
    @apply opacity-80;
  }

  &.filter {
    @apply bg-green-light text-white;
  }
}
</style>
